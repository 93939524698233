<template>
  <div id="main02">
    <div class="box" v-for="(item,index) in cards" :key="index">
        <router-link :to="item.path">
            <div class="img"><img v-img-lazy="item.url" :src="item.url" alt=""></div>
            <p>{{item.title}}</p>
        </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cards: [
        { path: '/about', url: require('../../../public/index/home-img_1.jpg'), title: '组织架构' },
        { path: '/partners', url: require('../../../public/index/home-img_4.jpg'), title: '合作机构' },
        { path: '/committee', url: require('../../../public/index/home-img_2.jpg'), title: '评审专家' },
        { path: '/examareaReg', url: require('../../../public/index/home-img_3.jpg'), title: '承办商申请' },
      ]
    }
  }
}
</script>

<style scoped>
    #main02 {
        display: inline-block;
        box-sizing: border-box;
        width: 90%;
        max-width: 1400px;
        margin: 5%;
        /* padding: 0 20px; */
        overflow: auto;
        white-space: nowrap;
        scroll-snap-type: x mandatory;
    }
    .box {
        display: inline-block;
        box-sizing: border-box;
        width: 85%;
        margin-right: 5%;
        background-color: #fff;
        border-radius: 5px;
        scroll-snap-align: start;
        cursor: pointer;
    }
    .img {
        overflow: hidden;
        max-width: 100%;
        border-radius: 5px 5px 0 0;
    }
    .img>img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 5px 5px 0 0;
        transition: transform 0.5s ease-out;
    }
    .box:hover .img>img {
        transform: scale(1.1);
    }
    p {
        line-height: 3.5rem;
        text-indent: 1em;
        color: #000;
    }
    .box:hover p {
        color: #C78A03;
    }
    @media screen and (min-width: 768px) {
        #main02 {
            display: flex;
            justify-content: space-between;
            margin: 10px auto;
            overflow-x: visible;
        }
        .box {
            width: 23.5%;
            margin-right: 0;
        }

    }

    @media (max-width:1200px) {
        #main02 {
            padding: 0 20px;
        }
    }
</style>
