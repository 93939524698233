<template>
<div id="footer">
    <div class="box">
      <h2>合作伙伴</h2>
      <FooterBanner></FooterBanner>
      <el-row>
        <el-col :xs="16" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="logo-box" style="height:100%">
             <!-- <router-link to="/home">
              <img
                class="logo"
                :src="$host + `${iconUrl}`"
                >
              </img>
             </router-link> -->
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="pagelink">
            <div v-for="(item,index) in pagelink" class="textlink" :key="index">
              <router-link :to="item.path"><span>{{ item.text }}</span></router-link>
            </div>
          </div>
        </el-col>
      </el-row>
      <div style="width:100%;max-width: 1200px;margin: 0 auto;text-align: center;">
        <span class="copy">Copyright© 2009-2022 All Rights Reserved.</span>
        <span class="copy">Powered by Amazon Corporation Limited.</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getPartnersListApi } from '@/api/cooperate/index';
import FooterBanner from './FooterBanner.vue';

export default {
  components: {
    FooterBanner
  },
  props:{
    sysCopyrightList:{
       type:Array,
       required: true
    },
    iconUrl:{
      type:String,
      required:true
    }
  },
  watch:{
    sysCopyrightList: {
       handler(newValue) {
        this.sysCopyrightData = newValue
       },
       deep: true,
       immediate:true

    }
  },
  data() {
    return {
      sysCopyrightData:[],
      cards: [],
      url: '',
      pagelink: [
        // { text: '考試指南', path: '' },
        
        // { text: '合作夥伴', path: '' },
        // { text: '隱私條例', path: '' },
        // { text: '最新消息', path: '' },
        // { text: '組織架構', path: '' },
        // { text: '會員條約', path: '' },
        // { text: '官方考點', path: '' },
        // { text: '聯絡我們', path: '' }
        { text: '承办列表', path: '/ContractorList' },
        { text: '關於我們', path: '/about' },
        { text: '專家評審', path: '/committee' },
        { text: '考级资讯', path: '/information' },
        { text: '考试公告', path: '/examtype' },
        { text: '考点查询', path: '/site' },
        { text: '考试时间', path: '/examtime' },
      ]
    }
  },
  methods: {
    /*锚链接跳转*/
    anchor(anchorName) {
      // /*找到锚点*/
      // let anchorElement = document.getElementById(anchorName);
      // /*如果对应id的锚点存在，就跳转到锚点*/
      // if(anchorElement) {
      //     anchorElement.scrollIntoView();
      // }
      document.querySelector(anchorName).scrollIntoView(true)
      let auchor = this.$el.querySelector(anchorName)
      if (auchor) {
        document.body.scrollTop = auchor.offsetTop
      }
    },
    async getData() {
      const type = 0
      const res = await getPartnersListApi(type)
      if (res.code == 0) {
        this.cards = res.data
      }
    },
  },
  created() {
    this.getData()
  }
}
</script>

<style scoped>
#footer {
  width: 100%;
  height: auto;
  background-color: #666;
  color: #fff;
  padding-bottom: 20px;
}


.box {
  width: 100%;
  max-width: 1200px;
  height: auto;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 40px 0 0 0;
}

.box > h2 {
  font-size: 2rem;
  text-align: center;
  font-weight: lighter;
  margin-bottom: 40px;
}

.logo-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: fit-content;
  height: 200px;
}

.pagelink {
  display: flex;
  flex-flow: row wrap;
  padding: 10px 20px;
}

.textlink, a {
  box-sizing: border-box;
  width: 25%;
  color: #fff;
  margin: 5px 0;
}

.copy {
  display: block;
  color: #8c8c8c;
  margin: 0 auto;
}

.backtop {
  position: sticky;
  bottom: 40px;
  margin: 0 25px 0 auto;
  display: block;
  width: 54px;
  height: 54px;
  background: url('../../../public/images/footerTop.png');
  background-size: 100% auto;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .logo {
    padding: 0;
  }

  .textlink:hover {
    color: #b3b3b3;
    cursor: pointer;
  }

  a:hover {
    color: #b3b3b3;
  }
}
</style>