<template>
  <!-- 捕获所有路由中不存在的页面 -->
  <div id="div">
    <div class="box">
      <img src="../../../public/images/ico-404.png" alt />
      <h1>
        警告！你正在访问不存在的页面，点此
        <a href="/">回到首页</a>
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: ""
    };
  },
  methods: {
    asd() {
      console.log(this.text);
    }
  }
};
</script>

<style scoped>
#div {
  z-index: 999;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
}

h1 {
  margin-top: 100px;
  text-align: center;
}

a {
  color: #ff0000;
}

.box {
  width: 100%;
  max-width: 1400px;
  height: fit-content;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

img {
  margin-top: 30px;
}
</style>
