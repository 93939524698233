<template>
  <div>
    <!-- PC端 -->
    <div class="header-container">
      <div class="header-box">
        <router-link to="/home">
          <div class="left-img-box">
            <img class="logo" :src="$host + `${iconUrl ? iconUrl : url}`" />
            <span>{{ title }}</span>
          </div>
        </router-link>
        <div class="right-box right-status">
          <div class="right-text-box">
            <div class="text-top-box">
              <ul>
                <li class="dropdown">
                  <!-- <router-link>登录</router-link> -->
                  <router-link style v-if="!token" to="/enroll">你好！请注册 {{ value }}</router-link>
                  <div v-else>
                    <div>{{ infoName }} 你好</div>
                    <div class="dropdown-box">
                      <div class="dropdown-item">
                        <router-link to="/info" class="dropdown-item">个人中心</router-link>
                      </div>
                      <div class="dropdown-item">
                        <router-link to="/pwd" class="dropdown-item">修改密码</router-link>
                      </div>
                      <div class="dropdown-item">
                        <router-link to="/onlineSignUpList" class="dropdown-item">报名列表</router-link>
                      </div>
                      <div class="dropdown-item" @click="loginOut">退出登入</div>
                    </div>
                  </div>
                </li>
                <!-- <li>
                  <router-link to="/examrelease">
                    成绩发布
                  </router-link>
                </li>
                <li>
                  打印通知
                </li>
                <li>
                 
                  <router-link to="/examtime">
                    考试时间
                  </router-link>
                </li>-->
              </ul>
            </div>
            <div class="text-bottom-box">
              <ul>
                <li>
                  <router-link to="/ContractorList">承办列表</router-link>
                </li>
                <li>
                  <router-link to="/about">关于我们</router-link>
                </li>
                <li>
                  <router-link to="/information">考级资讯</router-link>
                </li>
                <li>
                  <router-link to="/examtype">考试公告</router-link>
                </li>
                <li>
                  <router-link to="/site">考点查询</router-link>
                </li>
                <li>
                  <router-link to="/committee">评审考官</router-link>
                </li>
                <li>
                  <router-link to="/examtime">考试时间</router-link>
                </li>
                <!-- <li>
                  <router-link to="/award"> 奖状 </router-link>
                </li>-->
                <!-- <li>
                  打印通知
                </li>-->
              </ul>
            </div>
          </div>
          <div class="right-sign-up-box">
            <router-link style="color: #fff" :to="{path:'/onlineSignUp'}">
              <span>线上报名</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- 手机端 -->
    <div class="phone-container">
      <div class="phone-box">
        <router-link to="/home">
          <div class="left-img-box">
            <el-image class="logo" :src="$host + `${iconUrl ? iconUrl : url}`"></el-image>
            <span>{{ title }}</span>
          </div>
        </router-link>
        <div class="right-box">
          <!-- <div class="right-title-box" >
            <router-link to="/login">登入 / 注册</router-link>
          </div>-->
          <!-- <div v-else class="phone-after-title">
            <div class="phone-user">{{ infoName }} 你好</div>
            <div class="dropdown-box">
              <div class="dropdown-item"><router-link to="/info" class="dropdown-item"> 个人中心 </router-link></div>
              <div class="dropdown-item">
                <router-link to="/pwd" class="dropdown-item"> 修改密码 </router-link>
              </div>
              <div class="dropdown-item" @click="loginOut">退出登入</div>
            </div>
          </div>-->
          <div class="phone-after-title" v-if="value">
            <!-- <i class="el-icon-s-operation" style="font-size: 28px; color: #601986"></i> -->
            <div class="phone-user">{{ infoName }} 你好</div>
          </div>
          <div class="icon-box">
            <div v-if="isShow" @click="initIcon">
              <i class="el-icon-s-operation" style="font-size: 28px; color: #601986"></i>
            </div>
            <div v-else class="error-icon" @click="activeIcon">
              <i class="el-icon-close" style="font-size: 28px; color: #601986"></i>
            </div>
          </div>
        </div>
        <div class="list-box" id="initIcon">
          <ul>
            <li @click="activeIcon" v-for="(item,index) in infoList" :key="index">
              <router-link :to="item.path">{{item.name}}</router-link>
              <!-- <router-link to="/onlineSignUpList">报名列表</router-link> -->
            </li>
            <li @click="activeIcon" v-show="value">
              <router-link to="/info">个人中心</router-link>
            </li>
            <li @click="activeIcon" v-show="value">
              <router-link to="/onlineSignUpList">报名列表</router-link>
            </li>
            <li @click="activeIcon" v-show="value">
              <router-link to="/pwd">修改密码</router-link>
            </li>
            <!-- <li @click="activeIcon">
              <router-link to="/information">考级资讯</router-link>
              <router-link to="/onlineSignUp">打印通知</router-link>
            </li>
            <li @click="activeIcon">
              <router-link to="/examtype">考试公告</router-link>
              <router-link to="/onlineSignUp">线上报名</router-link>
            </li>
            <li @click="activeIcon">
              <router-link to="/site">考点查询</router-link>
              <router-link to="/info" v-show="value">个人中心</router-link>
            </li>
            <li @click="activeIcon">
              <router-link to="/committee">评审考官</router-link>
              <router-link to="/pwd" v-show="value">修改密码</router-link>
            </li>
            <li @click="activeIcon">
              <router-link to="/examtime">考试时间</router-link>
            </li>-->
            <li @click="activeIcon" v-show="value">
              <a @click="phoneLoginOut">退出登录</a>
            </li>
            <li @click="activeIcon" v-show="!value">
              <router-link to="/enroll">立即注册</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <PopUP v-model="isModel"></PopUP>
  </div>
</template>

<script>
import { Local } from "@/common/cookie";
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    iconUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      sysCopyrightData: [],
      isCollapse: true,
      showmenu: false,
      activeIndex: "/",
      token: Local.getToken("token"),
      isShow: true,
      isModel: false,
      infoList: [
        {
          name: "线上报名",
          path: { path: "/onlineSignUp"}
        },
        {
          name: "承办列表",
          path: "/ContractorList"
        },
        {
          name: "关于我们",
          path: "/about"
        },
        // {
        //   name: "报名列表",
        //   path: "/onlineSignUpList"
        // },
        {
          name: "考级资讯",
          path: "/information"
        },
        {
          name: "打印通知",
          path: "/onlineSignUp"
        },
        {
          name: "考试公告",
          path: "/examtype"
        },
        {
          name: "考点查询",
          path: "/site"
        },
        // {
        //   name: "个人中心",
        //   path: "/info"
        // },
        {
          name: "评审考官",
          path: "/committee"
        },
        // {
        //   name: "修改密码",
        //   path: "/pwd"
        // },
        {
          name: "考试时间",
          path: "/examtime"
        }
      ]
    };
  },
  watch: {
    $route() {
      this.setCurrentRoute(); //监测当前路由
    }
  },
  computed: {
    value() {
      return (this.token = this.$store.state.token
        ? this.$store.state.token
        : Local.getToken("token"));
    },
    infoName() {
      return this.$store.state.username
        ? this.$store.state.username
        : Local.getToken("userInfo")?.name;
    },
    url() {
      return Local.getToken("url");
    }
  },
  methods: {
    setCurrentRoute() {
      this.showmenu = false;
    },
    ismobilemenu() {
      this.showmenu = !this.showmenu;
    },
    loginOut() {
      this.$confirm("此操作将退出登入, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "persdsd"
      })
        .then(() => {
          Local.clear();
          this.$store.commit("setToken", "");
          this.$router.push("/login");
          this.$message({
            type: "success",
            message: "退出登入成功!"
          });
        })
        .catch(() => {});
    },
    phoneLoginOut() {
      this.isModel = true;
    },
    initIcon() {
      const menu = document.getElementById("initIcon");
      menu.style.height = "100%";
      this.isShow = false;
    },
    activeIcon() {
      const menu = document.getElementById("initIcon");
      menu.style.height = "0";
      this.isShow = true;
    }
  }
};
</script>

<style lang="less" scoped src="@/css/header/PCHeader.less"></style>
<style lang="less" scoped>
ul {
  list-style: none;
}

.phone-container {
  display: none;
}

@media (max-width: 1200px) {
  .header-container {
    display: none;
  }

  .phone-container {
    display: block;
    height: 8%;
  }

  .phone-container .phone-box {
    width: 100%;
    height: 8%;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: #fff;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .list-box {
      position: fixed;
      top: 8%;
      left: 0;
      background-color: #fff;
      z-index: 999;
      width: 100%;
      height: 0;
      overflow: auto;
      transition: all 1s ease;

      ul {
        margin: 0;
        padding: 0 0 50px 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0 30px 20px 20px;
          padding: 30px;
          border-bottom: 1px solid #666;

          a {
            font-size: 15px;
            color: #601986;
            font-weight: bold;
            margin-right: 5px;
          }

          .list-bottom-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
    }
  }

  .phone-container .phone-box .left-img-box {
    display: flex;
    align-items: center;
    width: fit-content;
    height: 100%;
    margin-left: 10px;
    cursor: pointer;

    .logo {
      width: 100%;
      max-width: 100px;
      height: 100%;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      min-width: 120px;
      font-size: 12px;
      height: 100%;
      font-weight: bold;
      color: #601986;
    }
  }

  .phone-box .right-box {
    display: flex;
    align-items: center;
    margin-right: 10px;

    .right-title-box {
      padding: 5px;
      height: 30px;
      background-color: #601986;
      border-radius: 10px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;

      a {
        font-size: 12px;
        color: #fff;
      }
    }

    .phone-after-title {
      color: #601986;
      font-size: 12px;
      display: flex;
      align-items: center;
      margin-right: 20px;
    }
  }
}
</style>
