<template>
  <div id="div">
    <div v-if="this.$route.name != 'home'" class="mainVisual">
      <div class="bread">
        <!-- <el-breadcrumb separator=">">
          <el-breadcrumb-item>
            <router-link to="/home" style="color:#c78a03">首页</router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            <router-link :to="this.$route.path" style="color:#c78a03">{{ this.$route.meta.nav }}</router-link>
          </el-breadcrumb-item>
        </el-breadcrumb>-->
        <div class="back-box" @click="onBack">< 返回 / <span>back</span> </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    onBack() {
      if (this.$route.query.show) {
        this.$router.push("/home");
      } else {
        this.$router.back()
      }
    },
    handleKeyDown(event) {
      if (event.key === "Backspace" || event.keyCode === 8) {
        const activeElement = document.activeElement;
        if (
          !activeElement ||
          !(
            activeElement.tagName === "INPUT" ||
            activeElement.tagName === "TEXTAREA"
          )
        ) {
          event.preventDefault();
          this.onBack();
        }
      }
    }
  },
  watch: {
    $route() {}
  },
  mounted() {
    document.addEventListener("keydown", this.handleKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeyDown);
  },
  created() {}
};
</script>

<style scoped>
.mainVisual {
  z-index: 10;
  width: 100%;
  height: 240px;
  background: url("../../../public/images/visual_img_2.png") no-repeat,
  linear-gradient(76deg, #fbfaf6, #ffebde);
  background-position: 50% 80%;
  background-size: 276px auto, 100% auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.back-box {
  cursor: pointer;
  color: #333;
  font-size: 16px;
  font-weight: bold;
}
.back-box span {
   font-weight: normal;
   color: #666;
}
.back-box:hover {
  color: #601986;
}
.back-box span:hover {
  color: #601986;
}
.bread {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 0;
  box-sizing: border-box;
  width: 100%;
  height: 1rem;
  max-width: 1400px;
  margin: 20px auto;
  /* padding-top: 80px; */
  /* padding: 0 20px; */
}
a {
  font-weight: 100;
}
@media screen and (min-width: 768px) {
  .mainVisual {
    height: 240px;
    background: url("../../../public/images/visual_img_2.png") no-repeat right
        47px bottom,
      linear-gradient(76deg, #fbfaf6, #ffebde);
  }
  .bread {
    padding-top: 120px;
  }
}
</style>