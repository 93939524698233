<template>
  <vue-seamless-scroll :data="cards" :class-option="classOption" class="warp">
    <div class="warp-item">
      <div v-for="item in cards" :key="item.id">
        <router-link :to="`/partners/partnersdetail/${item.id}`">
        <div class="logo-box">
            <img :src="$host + item.footerimg" alt />
            <div class="logo-title">{{ item.title_chi}}</div>
        </div>
       </router-link>
      </div>
    </div>
  </vue-seamless-scroll>
</template>

<script>
import { getPartnersListApi } from "@/api/cooperate/index";
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  components: {
    vueSeamlessScroll
  },
  data() {
    return {
      cards: [],
      listData: [1, 2, 3, 4, 5, 6],
      classOption: {
        limitMoveNum: 1,
        direction: 2
      }
    };
  },
  methods: {
    async getData() {
      const type = 0;
      const res = await getPartnersListApi(type);
      if (res.code == 0) {
        this.cards = res.data;
      
      }
    }
  },
  created() {
    this.getData();
  }
};
</script>

<style scoped lang="less">
.warp {
  width: 100%;
  max-width: 1200px;
  height: 120px;
  overflow: hidden;
  mask-image: linear-gradient(90deg,transparent,#666 10%,#666,90%,transparent);

  .warp-item {
    width: 100%;
    display: flex;
    height: fit-content;
  }

  .logo-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    width: fit-content;
    min-width: 250px;
    margin-right: 20px;
    // overflow: hidden;

    img {
      width: fit-content;
      min-width: 100px;
      height: 67px;
    }

    .logo-title {
      width: fit-content;
      text-align: center;
      color: #fff;
      margin-top: 5px;
      font-size: 14px;
    }
  }
}

@media (max-width:900px) {
   .warp {
     .logo-box {
       
       img {
           box-sizing: content-box;
           width: auto;
       }
     }
   }
}
</style>
